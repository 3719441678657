import React from 'react';
import OverviewInfoDTO from "../dto/OverviewInfoDTO";
import Item from "../ui/Item";
import {Unit} from "../util/Unit";
import Section from '../ui/Section';
import moment from "moment";
import Format from "../util/Format";

interface CarInfoProps {
    info: OverviewInfoDTO;
}

export default function CarInfo(props: CarInfoProps) {

    function onSameLocationSince() {
        if (props.info.car?.onSameLocationSince === undefined)
            return "--";

        const dateTime = moment.utc(props.info.car?.onSameLocationSince);
        const otherDay = (moment().format("YYYY-MM-DD") !== dateTime.format("YYYY-MM-DD"));
        return dateTime.format(otherDay ? "DD-MM-YYYY HH:mm:ss" : "HH:mm:ss");
    }

    function carBatteryDetails() {
        if (props.info.car?.range === undefined)
            return "--";

        return props.info.car?.range + " km /<br/> " + Format.forUnit(props.info.car?.batteryCapacity, Unit.WattHour);
    }

    function carConsumption() {
        if (props.info.car?.currentConsumption !== undefined && props.info.car?.currentConsumption > 0) {
            return <Item icon="box-arrow-in-right" textColor="text-blue-500" value={props.info.car?.currentConsumption}
                         unit={Unit.Watt}/>;
        } else {
            return <></>
        }
    }

    return (
        <>
            <Section header="Car">
                <Item
                    icon={Format.batteryIconFor("CHARGING" === props.info.car?.chargingStatus, props.info.car?.batteryPercentage).icon}
                    textColor={Format.batteryIconFor("CHARGING" === props.info.car?.chargingStatus, props.info.car?.batteryPercentage).textColor}
                    value={props.info.car?.batteryPercentage}
                    value2={carBatteryDetails()}
                    unit={Unit.Percentage}/>
                {carConsumption()}
                <Item icon="car-front" value={props.info.car?.infoMessages}/>
                <Item icon={props.info.car?.atHome ? "house-heart" : "geo"}
                      value={onSameLocationSince()}/>
            </Section>
            <Section header="Charging station">
                <Item icon="ev-station"
                      textColor={"CHARGING" === props.info.car?.chargingStatus ? "text-blue-500" : ""}
                      value={props.info.car?.chargingStatus}/>
            </Section>
        </>
    );
}
import Secret from "./util/Secret";
import axios from "axios";

const prod = {
    url: {
        API_URL: "https://power.dehaen.com"
    },
    ui_debug: false
};
const dev = {
    url: {
        API_URL: "http://localhost:8888"
    },
    ui_debug: true
};

Secret.initializeAuthorizationToken();
console.log("Initializing to run with authorization token " + Secret.initializeAuthorizationToken())
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authorization_token')}`;

export const config = process.env.NODE_ENV === "development" ? dev : prod;

export const constants = {
    margin: 5,
    titleSize: 20,
    textSize: 15,

    backgroundColor: "#282935",

    offlineColor: "white",
    chargingColor: "#54b0f3",
    supplyColor: "yellow",
    fullyChargedColor: "green",

    fullyChargedTextColor: "white"

}
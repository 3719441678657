import React from 'react';

interface SectionProps {
    header: string;
    children?: any;
}

export default function Section(props: SectionProps) {
    return (
        <div className="grid">
            <div className="col-12 lg:col-5 lg:vertical-align-text-top">
                <h3 className="mb-0 lg:mt-0">{props.header.toUpperCase()}</h3>
            </div>
            <div className="col-12 lg:col-7">
                <div className="pl-3">
                    {props.children}
                </div>
            </div>
        </div>
    );
}
import React from 'react';
import OverviewInfoDTO from "../dto/OverviewInfoDTO";
import Section from "../ui/Section";
import {Unit} from "../util/Unit";
import Item from "../ui/Item";
import Format from "../util/Format";

interface PowerInfoProps {
    info: OverviewInfoDTO;
}

export default function PowerInfo(props: PowerInfoProps) {

    return (
        <>
            <Section header="Home Battery">
                <Item icon={Format.batteryIconFor(props.info.homeBattery?.currentCharge !== undefined && props.info.homeBattery?.currentCharge > 0, props.info.homeBattery?.percentage).icon}
                      textColor={Format.batteryIconFor(props.info.homeBattery?.currentCharge !== undefined && props.info.homeBattery?.currentCharge > 0, props.info.homeBattery?.percentage).textColor}
                      value={props.info.homeBattery?.percentage}
                      value2={Format.forUnit(props.info.homeBattery?.capacity, Unit.WattHour)} unit={Unit.Percentage}/>
                <Item icon={props.info.homeBattery?.currentCharge !== undefined && props.info.homeBattery?.currentCharge > 0 ? "box-arrow-in-right" : "box-arrow-in-left"}
                      textColor={props.info.homeBattery?.currentCharge !== undefined && props.info.homeBattery?.currentCharge > 0 ? "text-yellow-500" : "text-blue-500"}
                      value={props.info.homeBattery?.currentCharge !== undefined
                      && props.info.homeBattery?.currentCharge > 0
                          ? props.info.homeBattery?.currentCharge
                          : props.info.homeBattery?.currentDischarge
                      }
                      unit={Unit.Watt}/>
            </Section>
            <Section header="Home">
                <Item icon="lightning-charge"
                      textColor="text-blue-500"
                      value={props.info.home?.currentConsumption} unit={Unit.Watt}/>
            </Section>
            <Section header="Total">
                <Item icon="lightning-charge"
                      textColor="text-blue-500"
                      value={props.info.home?.totalConsumption} unit={Unit.Watt}/>
            </Section>
            <Section header="Solar Power">
                <Item icon={props.info.solar?.currentGeneration === 0 ? "moon" : "sun"}
                      textColor={props.info.solar?.currentGeneration === 0 ? "text-blue-500" : "text-yellow-500"}
                      value={props.info.solar?.currentGeneration} unit={Unit.Watt}/>
            </Section>
            <Section header="Grid Power">
                <Item
                    icon={props.info.powerGrid?.currentSupplyConsumption !== undefined && props.info.powerGrid?.currentSupplyConsumption > 0 ? "sun" : "plug"}
                    textColor={props.info.powerGrid?.currentSupplyConsumption !== undefined && props.info.powerGrid?.currentSupplyConsumption > 0 ? "text-yellow-500" : "text-blue-500"}
                    value={props.info.powerGrid?.currentSupplyConsumption} unit={Unit.Watt}/>
            </Section>
        </>
    );
}
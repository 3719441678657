import React from "react";
import OverviewInfoDTO from "../dto/OverviewInfoDTO";
import {Layer, Line, Stage} from "react-konva";
import {config, constants} from "../Constants";
import {PowerLine} from "../ui/PowerLine";
import {Grid} from "../ui/Grid";
import {Sun} from "../ui/Sun";
import {Car} from "../ui/Car";
import {Home} from "../ui/Home";
import {HomeBattery} from "../ui/HomeBattery";

interface ImageOverviewProps {
    info: OverviewInfoDTO;
}

export default function ImageOverview(props: ImageOverviewProps) {

    const innerWidth = window.innerWidth - 30 - (config.ui_debug ? 450 : 0);
    const innerHeight = window.innerHeight - 110;

    const solarDiameter = 100;
    const carWidth = 165;
    const carHeight = 75;
    const gridHeight = 125;
    const gridWidth = 100;
    const homeHeight = 125;
    const homeWidth = 100;
    const homeBatteryWidth = 100;
    const homeBatteryHeight = 125
    const flowMargin = 20;

    function designGrid() {
        if (config.ui_debug) {
            return (
                <>
                    <Line x={0} y={constants.margin}
                          points={[
                              0, 0,
                              innerWidth, 0
                          ]}
                          stroke="red"
                    />
                    <Line x={0} y={innerHeight / 2}
                          points={[
                              0, 0,
                              innerWidth, 0
                          ]}
                          stroke="red"
                    />
                    <Line x={0} y={innerHeight - constants.margin}
                          points={[
                              0, 0,
                              innerWidth, 0
                          ]}
                          stroke="red"
                    />

                    <Line x={constants.margin} y={0}
                          points={[
                              0, 0,
                              0, innerHeight
                          ]}
                          stroke="red"
                    />
                    <Line x={innerWidth / 2} y={0}
                          points={[
                              0, 0,
                              0, innerHeight
                          ]}
                          stroke="red"
                    />
                    <Line x={innerWidth - constants.margin} y={0}
                          points={[
                              0, 0,
                              0, innerHeight
                          ]}
                          stroke="red"
                    />
                </>
            )
        }
        return <></>
    }

    function solar() {
        const power = props.info.solar?.currentGeneration !== undefined ? props.info.solar?.currentGeneration : 0;
        const solarGenerationHappening = power > 0;

        return (
            <>
                <Sun
                    x={innerWidth / 2}
                    y={(solarDiameter / 2) + constants.margin + flowMargin}
                    diameter={solarDiameter}
                    power={power}
                />
                {solarFlows(solarGenerationHappening)}
            </>
        );
    }

    function solarFlows(show: boolean) {

        function solarToCar() {
            if (props.info.flow?.solar?.toCar !== undefined && props.info.flow?.solar?.toCar > 0) {
                return <PowerLine
                    x={(innerWidth / 2) + (solarDiameter / 2) + (flowMargin * 2)}
                    y={(solarDiameter / 2) + constants.margin}
                    points={[
                        0, 0,
                        (innerWidth / 2) - (solarDiameter / 4) - (carWidth / 2) - flowMargin, 0,
                        (innerWidth / 2) - (solarDiameter / 4) - (carWidth / 2) - flowMargin, (innerHeight / 2) - (carHeight / 2) - (solarDiameter / 2) - flowMargin,
                    ]}
                    charging={true}
                    power={props.info.flow?.solar?.toCar}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        function solarToHome() {
            if (props.info.flow?.solar?.toHome !== undefined && props.info.flow?.solar?.toHome > 0) {
                return <PowerLine
                    x={(innerWidth / 2) - (solarDiameter / 4) - flowMargin}
                    y={solarDiameter + (flowMargin * 3)}
                    points={[
                        0, 0,
                        0, innerHeight - solarDiameter - (homeHeight / 3 * 2) - (flowMargin * 3),
                        -(innerWidth / 2) + (constants.margin * 3) + homeWidth + (solarDiameter / 2), innerHeight - solarDiameter - (homeHeight / 3 * 2) - (flowMargin * 3),
                    ]}
                    charging={false}
                    power={props.info.flow?.solar?.toHome}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        function solarToGrid() {
            if (props.info.flow?.solar?.toGrid !== undefined && props.info.flow?.solar?.toGrid > 0) {
                return <PowerLine
                    x={(innerWidth / 2) - (solarDiameter / 2) - (flowMargin * 2)}
                    y={(solarDiameter / 2) + constants.margin}
                    points={[
                        0, 0,
                        -(innerWidth / 2) + (solarDiameter / 2) + constants.margin + (gridWidth / 2) + flowMargin, 0,
                        -(innerWidth / 2) + (solarDiameter / 2) + constants.margin + (gridWidth / 2) + flowMargin, (innerHeight / 2) - (gridWidth / 2) - (solarDiameter / 2) - flowMargin,
                    ]}
                    charging={true}
                    power={props.info.flow?.solar?.toGrid}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        function solarToHomeBattery() {
            if (props.info.flow?.solar?.toHomeBattery !== undefined && props.info.flow?.solar?.toHomeBattery > 0) {
                return <PowerLine
                    x={(innerWidth / 2) + (solarDiameter / 4) + flowMargin}
                    y={solarDiameter + (flowMargin * 3)}
                    points={[
                        0, 0,
                        0, innerHeight - solarDiameter - (homeHeight / 3 * 2) - (flowMargin * 3),
                        (innerWidth / 2) - (constants.margin + homeBatteryWidth + flowMargin + (solarDiameter / 2)), innerHeight - solarDiameter - (homeHeight / 3 * 2) - (flowMargin * 3),
                    ]}
                    charging={true}
                    power={props.info.flow?.solar?.toHomeBattery}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        if (show) {
            return (
                <>
                    {solarToCar()}
                    {solarToHome()}
                    {solarToGrid()}
                    {solarToHomeBattery()}
                </>
            );
        }
        return <></>

    }

    function car() {
        return (
            <>
                <Car
                    x={innerWidth - constants.margin}
                    y={innerHeight / 2}
                    width={carWidth}
                    height={carHeight}
                    chargingStatus={props.info.car?.chargingStatus === undefined ? "" : props.info.car?.chargingStatus}
                    chargerConnected={props.info.car?.chargerConnected === undefined ? false : props.info.car?.chargerConnected}
                    batteryCapacity={props.info.car?.batteryCapacity === undefined ? 0 : props.info.car?.batteryCapacity}
                    batteryPercentage={props.info.car?.batteryPercentage === undefined ? 0 : props.info.car?.batteryPercentage}
                    range={props.info.car?.range === undefined ? 0 : props.info.car?.range}
                />
            </>
        );
    }

    function home() {
        return (
            <>
                <Home
                    x={constants.margin}
                    y={innerHeight - constants.margin}
                    width={homeWidth}
                    height={homeHeight}
                    power={props.info.home?.currentConsumption === undefined ? 0 : props.info.home?.currentConsumption}
                />
            </>
        );
    }

    function homeBattery() {
        return (
            <>
                <HomeBattery
                    x={innerWidth - homeBatteryWidth - constants.margin}
                    y={innerHeight - homeBatteryHeight - constants.margin}
                    width={homeBatteryWidth}
                    height={homeBatteryHeight}
                    percentage={props.info.homeBattery?.percentage === undefined ? 0 : props.info.homeBattery?.percentage}
                    capacity={props.info.homeBattery?.capacity === undefined ? 0 : props.info.homeBattery?.capacity}
                    power={props.info.homeBattery?.currentCharge !== undefined && props.info.homeBattery?.currentCharge
                        ? props.info.homeBattery?.currentCharge
                        : props.info.homeBattery?.currentDischarge !== undefined
                            ? props.info.homeBattery?.currentDischarge * -1
                            : 0}
                />
                {homeBatteryFlows(props.info.homeBattery?.currentDischarge !== undefined && props.info.homeBattery?.currentDischarge > 0)}
            </>
        );
    }

    function homeBatteryFlows(show: boolean) {

        function homeBatteryToCar() {
            if (props.info.flow?.homeBattery?.toCar !== undefined && props.info.flow?.homeBattery?.toCar > 0) {
                return <PowerLine
                    x={innerWidth - (homeBatteryWidth / 4) - flowMargin}
                    y={innerHeight - homeBatteryHeight - flowMargin - (flowMargin / 2) + constants.margin}
                    points={[
                        0, 0,
                        0, -(innerHeight / 2) + homeBatteryHeight + (carHeight / 2) + flowMargin + flowMargin
                    ]}
                    charging={true}
                    power={props.info.flow?.homeBattery?.toCar}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        function homeBatteryToHome() {
            if (props.info.flow?.homeBattery?.toHome !== undefined && props.info.flow?.homeBattery?.toHome > 0) {
                return <PowerLine
                    x={innerWidth - homeWidth - flowMargin}
                    y={innerHeight - flowMargin}
                    points={[
                        0, 0,
                        -(innerWidth - homeWidth - homeBatteryWidth - flowMargin - flowMargin), 0
                    ]}
                    charging={false}
                    power={props.info.flow?.homeBattery?.toHome}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        function homeBatteryToGrid() {
            if (props.info.flow?.homeBattery?.toGrid !== undefined && props.info.flow?.homeBattery?.toGrid > 0) {
                return <PowerLine
                    x={innerWidth - homeBatteryWidth - flowMargin}
                    y={innerHeight - (homeBatteryHeight / 3)}
                    points={[
                        0, 0,
                        -(innerWidth / 2) + homeBatteryWidth, 0,
                        -(innerWidth / 2) + homeBatteryWidth, -(innerHeight / 2) + (homeBatteryHeight / 2),
                        -innerWidth + homeBatteryWidth + gridWidth + (flowMargin * 2), -(innerHeight / 2) + (homeBatteryHeight / 2),
                    ]}
                    charging={true}
                    power={props.info.flow?.homeBattery?.toGrid}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        if (show) {
            return (
                <>
                    {homeBatteryToCar()}
                    {homeBatteryToHome()}
                    {homeBatteryToGrid()}
                </>
            );
        }
        return <></>
    }

    function grid() {
        const currentSupplyConsumption = props.info.powerGrid?.currentSupplyConsumption === undefined ? 0 : props.info.powerGrid?.currentSupplyConsumption;
        const gridConsumptionHappening = Math.abs(currentSupplyConsumption) > 0;

        return (
            <>
                <Grid
                    x={constants.margin}
                    y={(innerHeight / 2) - (gridWidth / 2)}
                    width={gridWidth}
                    height={gridHeight}
                    power={currentSupplyConsumption}
                />
                {gridFlows(gridConsumptionHappening)}
            </>
        );
    }

    function gridFlows(show: boolean) {

        function gridToCar() {
            if (props.info.flow?.grid?.toCar !== undefined && props.info.flow?.grid?.toCar > 0) {
                return <PowerLine
                    x={gridWidth + flowMargin}
                    y={(innerHeight / 2) - flowMargin}
                    points={[
                        0, 0,
                        innerWidth - gridWidth - carWidth - flowMargin - flowMargin, 0
                    ]}
                    charging={true}
                    power={props.info.flow?.grid?.toCar}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        function gridToHome() {
            if (props.info.flow?.grid?.toHome !== undefined && props.info.flow?.grid?.toHome > 0) {
                return <PowerLine
                    x={(gridWidth / 4) + (flowMargin / 2)}
                    y={(innerHeight / 2) + (gridHeight / 2) + flowMargin}
                    points={[
                        0, 0,
                        0, (innerHeight / 2) - homeHeight - (gridHeight / 2) - flowMargin - flowMargin
                    ]}
                    charging={false}
                    power={props.info.flow?.grid?.toHome}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        function gridToHomeBattery() {
            if (props.info.flow?.grid?.toHomeBattery !== undefined && props.info.flow?.grid?.toHomeBattery > 0) {
                return <PowerLine
                    x={gridWidth + flowMargin}
                    y={(innerHeight / 2) + (gridWidth / 4)}
                    points={[
                        0, 0,
                        (innerWidth / 2) - gridWidth, 0,
                        (innerWidth / 2) - gridWidth, (innerHeight / 2) - (gridWidth / 4) - (homeBatteryWidth / 4) - flowMargin,
                        (innerWidth) - gridWidth - homeBatteryWidth - flowMargin - flowMargin, (innerHeight / 2) - (gridWidth / 4) - (homeBatteryWidth / 4) - flowMargin
                    ]}
                    charging={true}
                    power={props.info.flow?.grid?.toHomeBattery}
                    maxHeight={innerHeight}
                    maxWidth={innerWidth}
                />
            }
            return <></>
        }

        if (show) {
            return (
                <>
                    {gridToCar()}
                    {gridToHome()}
                    {gridToHomeBattery()}
                </>
            );
        }
        return <></>
    }

    return (
        <>
            <Stage width={innerWidth} height={innerHeight}>
                <Layer>

                    {designGrid()}

                    {solar()}
                    {car()}
                    {home()}
                    {homeBattery()}
                    {grid()}

                </Layer>
            </Stage>
        </>
    );

}
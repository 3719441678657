import React from 'react';
import {Circle, Text} from "react-konva";
import Format from "../util/Format";
import {Unit} from "../util/Unit";
import Konva from "konva";
import {constants} from "../Constants";

export interface SunProps {
    x: number;
    y: number;
    diameter: number;
    power: number;
}

interface SunState {
}

export class Sun extends React.Component<SunProps, SunState> {
    state: SunState = {}
    sunRef = React.createRef<Konva.Circle>();
    rayAngle = 9
    firstLight = true;
    rays: Konva.Line[] = [];
    animation: Konva.Animation | undefined;

    componentDidMount() {
        const solarGenerationHappening = this.props.power > 0
        if (solarGenerationHappening) {

            this.drawRays()

            const rays = this.rays;
            const centerX = this.props.x;
            const centerY = this.props.y;
            const radius2 = (this.props.diameter / 2) + (constants.margin * 4);
            const rayAngle = this.rayAngle;
            let firstLight = this.firstLight;

            this.animation = new Konva.Animation(function (frame) {
                const tick = frame?.time !== undefined ? Math.round(frame.time) : 0;
                if (Math.round(tick / 40) % 4 === 0) {

                    rays.forEach((ray, i) => {

                        if (firstLight || Math.round(Math.random() * 100) % 7 === 0) {
                            const variableLength = Math.random() * 20;
                            const variableThickness = (Math.random() * 3 ) +1;
                            const x2 = Math.round(centerX + (radius2 + variableLength) * Math.cos(i * rayAngle));
                            const y2 = Math.round(centerY + (radius2 + variableLength) * Math.sin(i * rayAngle));

                            ray.points([0, 0, x2 - ray.x(), y2 - ray.y()])
                            ray.stroke(x2 % 2 === 0 ? "orange" : "yellow")
                            ray.strokeWidth(variableThickness)
                        }
                    })
                    firstLight = false;
                }
            })
            this.animation.start();

        }
    }

    componentDidUpdate(prevProps: Readonly<SunProps>, prevState: Readonly<SunState>, snapshot?: any) {
        const prevGenerates = prevProps.power > 0;
        const generates = this.props.power > 0;
        if (prevGenerates !== generates) {
            this.componentWillUnmount();
            this.componentDidMount();
            this.firstLight = true
        }
    }

    componentWillUnmount() {
        if (this.rays.length !== 0) {
            this.rays.forEach(ray => {
                ray.destroy() // remove sun ray from UI
            })
            this.rays.splice(0); // clear the array of Rays
        }
        if (this.animation !== undefined) {
            this.animation.stop();
        }
    }

    drawRays() {
        const radius1 = (this.props.diameter / 2) + constants.margin;
        const centerX = this.props.x;
        const centerY = this.props.y;
        for (let i = 0; i <= 330; i += this.rayAngle) { // 8 & 9; 330° is apparantly enough to circle the sun
            const x1 = Math.round(centerX + radius1 * Math.cos(i));
            const y1 = Math.round(centerY + radius1 * Math.sin(i));
            const ray = new Konva.Line({
                x: x1,
                y: y1,
                key: "key_" + i,
                points: [0, 0, 0, 0],
            });
            this.rays.push(ray)
            this.sunRef.current?.getLayer()?.add(ray)
        }
    }

    render() {
        const solarGenerationHappening = this.props.power > 0
        return <>
            <Circle x={this.props.x} y={this.props.y}
                    radius={this.props.diameter / 2}
                    stroke={solarGenerationHappening ? constants.supplyColor : constants.offlineColor}
                    fill={solarGenerationHappening ? constants.supplyColor : constants.offlineColor}
                    ref={this.sunRef}
            />
            <Text text={solarGenerationHappening ? "Sun" : "Moon"}
                  x={this.props.x - (this.props.diameter / 2)}
                  y={this.props.y + (this.props.power === 0 ? -10 : -20)}
                  fontSize={constants.titleSize} fontStyle="bold"
                  align="center" width={this.props.diameter}
                  fill={constants.backgroundColor}
            />
            <Text text={solarGenerationHappening ? Format.forUnit(this.props.power, Unit.Watt) : ""}
                  x={this.props.x - (this.props.diameter / 2)}
                  y={this.props.y + 10}
                  fontSize={constants.textSize}
                  align="center" width={this.props.diameter}
            />
        </>
    }

}
import React from 'react';
import Item from "../ui/Item";
import {Unit} from "../util/Unit";
import OverviewInfoDTO from "../dto/OverviewInfoDTO";
import Section from "../ui/Section";

interface WeatherInfoProps {
    info: OverviewInfoDTO;
}

export default function WeatherInfo(props: WeatherInfoProps) {
    return (
        <>
            <Section header="Weather">
                <Item icon="thermometer" value={props.info.weather?.temperatureAtHome} unit={Unit.Degree}/>
            </Section>
        </>
    );
}
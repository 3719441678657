import React, {useState} from 'react';
import {ToggleButton} from "primereact/togglebutton";
import {Button} from "primereact/button";
import axios from "axios";
import CarControlDTO from '../dto/CarControlDTO';
import {config} from "../Constants";
import {InputNumber} from "primereact/inputnumber";
import Format from "../util/Format";
import {useInterval, useMountEffect} from "primereact/hooks";

export default function CarControl() {

    const [info, setInfo] = useState<CarControlDTO>({
        action: "",
        automaticMode: "",
        charging: false,
        doorsOpen: false,
        drainUntil: 0
    });

    const loadStatus = () => {
        axios.get<CarControlDTO>(config.url.API_URL + "/api/car-control/status")
            .then(response => {
                setInfo(response.data);
            });
    };

    useMountEffect(() => loadStatus());

    useInterval(
        () => loadStatus(), // initial load of the data
        30 * 1000 // let's keep it refreshed every 30 seconds, as every action also refreshed the status
    );

    function enableAutomaticMode() {
        axios.get<CarControlDTO>(config.url.API_URL + "/api/car-control/enable-automatic-mode")
            .then(response => {
                setInfo(response.data);
            });
    }

    function charge() {
        axios.get<CarControlDTO>(config.url.API_URL + "/api/car-control/" + (!info?.charging ? "start-charging" : "stop-charging"))
            .then(response => {
                setInfo(response.data);
            });
    }

    function drain() {
        axios.get<CarControlDTO>(config.url.API_URL + "/api/car-control/drain")
            .then(response => {
                setInfo(response.data);
            });
    }

    async function drainUntil(drainUtil: number) {
        await axios.post<CarControlDTO>(config.url.API_URL + "/api/car-control/drain", {"until": drainUtil});
    }

    function doors() {
        axios.get<CarControlDTO>(config.url.API_URL + "/api/car-control/" + (!info?.doorsOpen ? "unlock-doors" : "lock-doors"))
            .then(response => {
                setInfo(response.data);
            });
    }

    function locate() {
        axios.get(config.url.API_URL + "/api/car-control/locate")
            .then(response => {
                setInfo(response.data);
            });
    }

    return (
        <>
            <div>
                <ToggleButton checked={info?.automaticMode === "AUTOMATIC"}
                              className="mb-2"
                              style={{width: "100%"}}
                              onLabel={Format.camelize(info?.automaticMode)}
                              offLabel={Format.camelize(info?.automaticMode)}
                              onIcon="bi bi-car-front"
                              offIcon="bi bi-car-front"
                              disabled={info?.automaticMode === "AUTOMATIC"}
                              onChange={() => enableAutomaticMode()}
                />
            </div>
            <div>
                <ToggleButton checked={info?.charging}
                              className="mb-2"
                              style={{width: "100%"}}
                              onLabel="Stop charging"
                              offLabel="Charge now"
                              onIcon="bi bi-battery-charging"
                              offIcon="bi bi-lightning-charge"
                              onChange={() => charge()}
                />
            </div>
            <div>
                <Button label="Drain battery"
                        icon="bi bi-battery"
                        className="mb-2"
                        style={{width: "100%"}}
                        outlined
                        onClick={() => drain()}
                />
            </div>
            <div>
                <InputNumber value={info?.drainUntil}
                             showButtons
                             buttonLayout="horizontal"
                             incrementButtonIcon="pi pi-plus"
                             decrementButtonIcon="pi pi-minus"
                             style={{width: "100%", textAlignLast: "center"}}
                             className="mb-2"
                             onChange={(e) => drainUntil(e.value != null ? e.value : 50)}
                />
            </div>
            <div>
                <ToggleButton checked={info?.doorsOpen}
                              className="mb-2"
                              style={{width: "100%"}}
                              onLabel="Close doors"
                              offLabel="Open doors"
                              onIcon="bi bi-door-closed"
                              offIcon="bi bi-door-open"
                              onChange={() => doors()}
                />
            </div>
            <div>
                <Button label="Locate"
                        className="mb-2"
                        style={{width: "100%"}}
                        outlined
                        onClick={() => locate()}
                />
            </div>
        </>
    );
}
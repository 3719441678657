import React, {useState} from 'react';
import OverviewInfoDTO from "./dto/OverviewInfoDTO";
import axios from "axios";

import './App.css';
import "primereact/resources/themes/soho-dark/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import CarControl from "./component/CarControl";
import ChartInfo from "./component/ChartInfo";
import {config} from "./Constants";
import {TabPanel, TabView} from "primereact/tabview";
import CarOverview from "./component/CarOverview";
import EventOverview from './component/EventOverview';
import {useInterval, useMountEffect} from "primereact/hooks";
import ImageOverview from "./component/ImageOverview";
import DebugOverview from "./component/DebugOverview";
import TestOverview from "./component/TestOverview";
import LocationOverview from "./component/LocationOverview";

export default function App() {

    const [info, setInfo] = useState<OverviewInfoDTO>({});

    const loadInfo = () => {
        axios.get<OverviewInfoDTO>(config.url.API_URL + "/api/info")
            .then(response => {
                setInfo(response.data);
            });
    };

    useMountEffect(() => loadInfo());

    useInterval(() => loadInfo(), 2 * 1000);

    function addDebugCarOverview() {
        if (config.ui_debug)
            return <CarOverview info={info}/>
        else
            return <></>
    }

    return (
        <TabView>
            <TabPanel header="Overview">
                <div className="grid">
                    <div className="col-12 lg:col-9">
                        <ImageOverview info={info}/>
                    </div>
                    <div className="hidden lg:col-3 lg:inline">
                        {addDebugCarOverview()}
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Control">
                <div className="grid">
                    <div className="col-12 lg:col-6 lg:flex-order-1 lg:mt-7">
                        <CarControl/>
                    </div>
                    <div className="col-12 lg:col-6 lg:flex-order-0">
                        <CarOverview info={info}/>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Info" className="lg:hidden">
                <CarOverview info={info}/>
            </TabPanel>
            <TabPanel header="Location">
                <LocationOverview info={info}/>
            </TabPanel>
            <TabPanel header="History">
                <div className="grid">
                    <div className="col-12 lg:col-9">
                        <ChartInfo/>
                    </div>
                    <div className="hidden lg:col-3 lg:inline">
                        <CarOverview info={info}/>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Events" className="hidden lg:inline">
                <EventOverview/>
            </TabPanel>
            <TabPanel header="Debug" className="hidden lg:inline">
                <DebugOverview/>
            </TabPanel>
            <TabPanel header="Test" className="hidden lg:inline">
                <TestOverview/>
            </TabPanel>
        </TabView>
    );
}
import React, {useState} from 'react';
import {Layer, Stage} from "react-konva";
import {PowerLine} from "../ui/PowerLine";
import {useInterval} from "primereact/hooks";
import {Grid} from "../ui/Grid";
import {Sun} from "../ui/Sun";
import {Car} from "../ui/Car";
import {Home} from "../ui/Home";
import {HomeBattery} from "../ui/HomeBattery";

export default function TestOverview() {

    const [power, setPower] = useState<number>(0);

    useInterval(() => {
        setPower((power > 3500) ? 0 : power + 400);
    }, 1000);


    function testElements() {
        const elements: JSX.Element[] = [];
        for (let index = 0; index < 12; index++) {
            elements.push(testPowerLines(index));
            elements.push(testGrids(index));
            elements.push(testSuns(index));
            elements.push(testCars(index));
            elements.push(testHomes(index));
            elements.push(testHomeBatteries(index));
        }
        return elements;

    }

    function testGrids(index: number) {
        return <>
            <Grid
                x={10 + (index * 150)}
                y={500}
                width={100}
                height={125}
                power={power === 0 ? power : index % 3 === 0 ? -power : index % 3 === 1 ? 0 : power}
            />
        </>
    }

    function testSuns(index: number) {
        const solarDiameter = 100;
        return <>
            <Sun
                x={60 + (index * (solarDiameter + 50))}
                y={750}
                diameter={solarDiameter}
                power={index % 2 === 0 ? 0 : power}
            />
        </>
    }

    function testCars(index: number) {
        if (index > 7)
            return <></>
        const carWidth = 165;
        const carHeight = 75;
        return <>
            <Car
                x={180 + (index * (carWidth + 50))}
                y={900}
                width={carWidth}
                height={carHeight}
                chargingStatus={index % 4 === 2 ? "CHARGING" : ""}
                chargerConnected={index % 4 > 0}
                batteryCapacity={index % 4 === 0 ? 0 : (index % 4 === 1 ? 74 : power % 74) * 1000}
                batteryPercentage={index % 4 === 0 ? 0 : index % 4 === 1 ? 100 : power % 100}
                range={index % 4 === 0 ? 0 : index % 4 === 1 ? 385 : power % 100}
            />
        </>
    }

    function testHomes(index: number) {
        const homeWidth = 100;
        const homeHeight = 125;
        return <>
            <Home
                x={10 + (index * (homeWidth + 50))}
                y={1100}
                width={homeWidth}
                height={homeHeight}
                power={power}
            />
        </>
    }

    function testHomeBatteries(index: number) {
        const homeBatteryWidth = 100;
        const homeBatteryHeight = 125
        return <>
            <HomeBattery
                x={10 + (index * (homeBatteryWidth + 50))}
                y={1200}
                width={homeBatteryWidth}
                height={homeBatteryHeight}
                percentage={Math.round(100 * index / 11)}
                capacity={Math.round(11000 * index / 11)}
                power={800 * (index % 2 === 0 ? 1 : -1)}
            />
        </>
    }

    function testPowerLines(index: number) {
        return <>
            <PowerLine x={10 + (40 * index)} y={10} points={[0, 0, 0, 400]}
                       charging={false}
                       power={100 + (300 * index)}
                       maxHeight={window.innerHeight} maxWidth={window.innerWidth}/>
            <PowerLine x={530 + (40 * index)} y={410} points={[0, 0, 0, -400]}
                       charging={true}
                       power={100 + (300 * index)}
                       maxHeight={window.innerHeight} maxWidth={window.innerWidth}/>
            <PowerLine x={1020} y={10 + (40 * index)} points={[0, 0, 400, 0]}
                       charging={false}
                       power={100 + (300 * index)}
                       maxHeight={window.innerHeight} maxWidth={window.innerWidth}/>
            <PowerLine x={1890} y={10 + (40 * index)} points={[0, 0, -400, 0]}
                       charging={false}
                       power={100 + (300 * index)}
                       maxHeight={window.innerHeight} maxWidth={window.innerWidth}/>
        </>
    }

    return (
        <>
            <Stage width={window.innerWidth} height={window.innerHeight}>
                <Layer>
                    {testElements()}

                    <PowerLine x={10} y={450} points={[0, 0, 990, 0]}
                               charging={true}
                               power={power}
                               maxHeight={window.innerHeight} maxWidth={window.innerWidth}/>
                    <HomeBattery
                        x={1810}
                        y={1200}
                        width={100}
                        height={125}
                        percentage={Math.round(power > 1800 ? 100 - ((power - 1800) / 1800 * 100) : power / 1800 * 100)}
                        capacity={Math.round(power > 1800 ? 11000 - ((power - 1800) / 3600 * 11000) : power / 3600 * 11000)}
                        power={power > 1800 ? -1 * power : power}
                    />

                </Layer>
            </Stage>
        </>
    );
}
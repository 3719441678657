import React from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import OverviewInfoDTO, {LocationDTO} from "../dto/OverviewInfoDTO";

interface LocationOverviewProps {
    info: OverviewInfoDTO;
}

export default function LocationOverview(props: LocationOverviewProps) {

    const rowClass = (data: LocationDTO) :string => {
        return data.rangeColor === null
            ? ''
            : 'text-blue-900 ' + data.rangeColor;
    }

    return (
        <>
            <div className="grid">
                <DataTable value={props.info.locations}
                           stripedRows
                           sortField="start" sortOrder={1}
                           rowClassName={rowClass}
                >
                    <Column field="start" sortable header="Start" dataType="date"/>
                    <Column field="appointment" sortable header="Appointment"/>
                    <Column field="location" sortable header="Location"/>
                    <Column field="duration" sortable header="Duration"/>
                    <Column field="distance" sortable header="Distance"/>
                    <Column field="runningTotalDistance" sortable header="Total Distance"/>
                    <Column field="range" sortable header="Range on battery"/>
                </DataTable>
            </div>
        </>
    );
}
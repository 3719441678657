import {Unit} from "./Unit";

interface BatteryStyle {
    icon: string;
    textColor: string;
}

export default class Format {
    static forUnit(value: any, unit?: Unit) {

        if (value === undefined || unit === undefined) {
            console.debug("<< value (" + value + ") or unit (" + unit + ") is undefined >>");
            return "--";
        }

        let isWatt = unit.valueOf() === Unit.Watt.valueOf();
        let isWattHour = unit.valueOf() === Unit.WattHour.valueOf();
        if (isWatt || isWattHour) {
            const watt = Number(value);
            if (watt < 1000)
                return watt.toLocaleString(undefined, {maximumFractionDigits: 2}) + " W" + (isWattHour ? "h" : "");
            else if (watt < 1000000)
                return (watt / 1000).toLocaleString(undefined, {maximumFractionDigits: 2}) + " kW" + (isWattHour ? "h" : "");
            else if (watt < 1000000000)
                return (watt / 1000 / 1000).toLocaleString(undefined, {maximumFractionDigits: 2}) + " MW" + (isWattHour ? "h" : "");
        } else if (unit.valueOf() === Unit.Percentage.valueOf()) {
            return value.toString() + "%";
        } else if (unit.valueOf() === Unit.Degree.valueOf()) {
            return value.toString() + "°C";
        }

        return "Unable to format --> " + value.toString() + " as it is a " + value.constructor.name;
    }

    static batteryIconFor(charging: boolean, value?: number) {
        if (value === undefined)
            return {icon: "battery", textColor: "text-red-500"} as BatteryStyle;
        else if (charging)
            return {icon: "battery-charging", textColor: "text-blue-500"} as BatteryStyle;
        else if (value > 80)
            return {icon: "battery-full", textColor: "text-green-500"} as BatteryStyle;
        else if (value > 45)
            return {icon: "battery-half", textColor: "text-yellow-500"} as BatteryStyle;
        else
            return {icon: "battery", textColor: "text-red-500"} as BatteryStyle;
    }

    static camelize(stringValue: string) {
        return stringValue.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index === 0 ? word.toUpperCase() : word.toLowerCase();
        }).replace(/\s+/g, '');
    }

}

import React from 'react';
import Item from "../ui/Item";
import {Unit} from "../util/Unit";
import OverviewInfoDTO from "../dto/OverviewInfoDTO";
import Section from "../ui/Section";

interface TotalPowerInfoProps {
    info: OverviewInfoDTO;
}

export default function TotalPowerInfo(props: TotalPowerInfoProps) {
    return (
        <>
            <Section header="Consumption">
                <Item icon="sun" textColor="text-yellow-500" value={props.info.powerGrid?.totalConsumedFromPowerGridDay} unit={Unit.WattHour}/>
                <Item icon="moon" textColor="text-blue-500" value={props.info.powerGrid?.totalConsumedFromPowerGridNight} unit={Unit.WattHour}/>
            </Section>
            <Section header="Supply">
                <Item icon="sun" textColor="text-yellow-500" value={props.info.powerGrid?.totalSuppliedToThePowerGridDay} unit={Unit.WattHour}/>
                <Item icon="moon" textColor="text-blue-500" value={props.info.powerGrid?.totalSuppliedToThePowerGridNight} unit={Unit.WattHour}/>
            </Section>
            <Section header="Generation">
                <Item icon="sun" textColor="text-yellow-500" value={props.info.solar?.totalGeneration} unit={Unit.WattHour}/>
            </Section>
        </>
    );
}
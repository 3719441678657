import React, {useState} from 'react';
import axios from "axios";
import {config} from "../Constants";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useMountEffect} from "primereact/hooks";
import {Divider} from "primereact/divider";

export default function DebugOverview() {

    const [rules, setRules] = useState<any>({});
    const [selectedRule, setSelectedRule] = useState<any>("");

    const loadDebug = () => {
        // get annotations
        axios.get<any>(config.url.API_URL + "/api/debug/execute-rules")
            .then(response => {
                setRules(response.data);
            });
    };

    useMountEffect(() => loadDebug());

    function showDebugDetails() {
        if (selectedRule !== null && selectedRule !== "") {
            return <>
                <Divider align="left">
                    <div className="inline-flex align-items-center">
                        <i className="pi pi-eye mr-2"></i>
                        <b>Debug info</b>
                    </div>
                </Divider>
                <div dangerouslySetInnerHTML={{
                    __html: selectedRule.debug.join("<br/>")
                }}/>
                <Divider align="left" className="mt-5">
                    <div className="inline-flex align-items-center">
                        <i className="pi pi-eye mr-2"></i>
                        <b>Status</b>
                    </div>
                </Divider>
                <div dangerouslySetInnerHTML={{
                    __html: JSON.stringify(selectedRule.status, null, 2)
                        .replaceAll("\n", "<br/>")
                        .replaceAll(" ", "&nbsp;&nbsp;&nbsp;")
                }}/>
            </>
        }
        return <></>
    }

    return (
        <>
            <div className="grid">
                <div className="col-12 lg:col-6">
                    <DataTable value={rules}
                               stripedRows
                               sortField="timestamp" sortOrder={-1}
                               selectionMode="single" selection={selectedRule} onSelectionChange={(e) => {
                        setSelectedRule(e.value)
                    }}
                    >
                        <Column field="actions" sortable header="Actions"/>
                        <Column field="rule" sortable header="Rule"/>
                    </DataTable>
                </div>
                <div className="col-12 lg:col-6">
                    {showDebugDetails()}
                </div>
            </div>
        </>
    );
}
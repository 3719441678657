import {v4 as uuid} from 'uuid';

export default class Secret {

    static initializeAuthorizationToken(): string {
        let secret = localStorage.getItem('authorization_token');
        if ( secret === null) {
            localStorage.setItem('authorization_token', uuid());
            return Secret.initializeAuthorizationToken();
        }
        return secret;
    }

}

import React from 'react';
import {Rect, Text} from "react-konva";
import Format from "../util/Format";
import {Unit} from "../util/Unit";
import Konva from "konva";
import {constants} from "../Constants";

export interface GridProps {
    x: number;
    y: number;
    width: number;
    height: number;
    power: number
}

interface GridState {
}

export class Grid extends React.Component<GridProps, GridState> {
    state: GridState = {}
    progressLineRef = React.createRef<Konva.Rect>();
    progressRef = React.createRef<Konva.Rect>();
    animation: Konva.Animation | undefined;

    componentDidMount() {
        if (this.props.power !== 0 && this.progressLineRef.current !== null && this.progressRef.current !== null) {
            const progressLine = this.progressLineRef.current;
            const progressBar = this.progressRef.current;
            const consumes = this.props.power < 0;
            const x = this.props.x + (constants.margin * 3);
            const self = this;
            this.animation = new Konva.Animation(function (frame) {
                const tick = frame?.time !== undefined ? Math.round(frame.time) : 0;
                const speed = 10 * (4 - Math.round(Math.abs(self.props.power) / 1000))
                const locationOnProgressBar = Math.round(tick / (speed > 0 ? speed : 10) % (progressLine.width() - progressBar.width()));
                progressBar.x(
                    consumes
                        ? x + locationOnProgressBar
                        : x + progressLine.width() - locationOnProgressBar - progressBar.width()
                );
            })
            this.animation.start();
        }
    }

    componentDidUpdate(prevProps: Readonly<GridProps>, prevState: Readonly<GridState>, snapshot?: any) {
        const previous = prevProps.power === 0 ? "offgrid" : prevProps.power < 0 ? "consumes" : "provides";
        const current = this.props.power === 0 ? "offgrid" : this.props.power < 0 ? "consumes" : "provides";
        if (this.props.power === 0 || previous !== current) {
            this.componentWillUnmount();
            this.componentDidMount();
        }
    }

    componentWillUnmount() {
        if (this.animation !== undefined) {
            this.animation.stop();
        }
    }

    render() {
        const offGrid = this.props.power === 0
        const absCurrentSupplyConsumption = Math.abs(this.props.power);
        const gridConsumptionHappening = absCurrentSupplyConsumption > 0;
        const textColor = gridConsumptionHappening ? (this.props.power <= 0 ? constants.supplyColor : constants.chargingColor) : constants.backgroundColor;
        const color = gridConsumptionHappening ? constants.backgroundColor : constants.offlineColor;
        return <>
            <Rect x={this.props.x} y={this.props.y}
                  width={this.props.width} height={this.props.height}
                  cornerRadius={5}
                  fill={color}
                  stroke={textColor}/>
            <Rect x={this.props.x + constants.margin + 1} y={this.props.y + constants.margin + 1}
                  width={this.props.width - (constants.margin * 2) - 2} height={this.props.height / 7 * 3}
                  cornerRadius={5}
                  fill={textColor}
                  stroke={textColor}/>
            <Rect x={this.props.x + constants.margin + 5} y={this.props.y + (constants.margin * 3)}
                  width={this.props.width - (constants.margin * 2) - 10} height={22}
                  fill={offGrid ? textColor : color}
                  stroke={offGrid ? textColor : color}/>
            <Rect x={this.props.x + constants.margin + 5} y={this.props.y + (constants.margin * 9)}
                  width={this.props.width - (constants.margin * 2) - 10} height={6}
                  fill={color}
                  stroke={color}
                  ref={this.progressLineRef}
            />
            <Rect x={this.props.x + constants.margin + 8} y={this.props.y + (constants.margin * 9) + 2.5}
                  width={this.props.width - (constants.margin * 2) - 16} height={1}
                  fill={textColor}
                  stroke={textColor}
                  ref={this.progressLineRef}
            />
            <Rect x={this.props.x + ((this.props.width - constants.margin) / 2) - (5 / 2)}
                  y={this.props.y + (constants.margin * 9) + 2}
                  width={5} height={2}
                  stroke="red"
                  ref={this.progressRef}
            />
            <Text text="Grid"
                  x={this.props.x}
                  y={this.props.y + (this.props.height / 10 * 7)}
                  fontSize={constants.titleSize} fontStyle="bold"
                  align="center" width={this.props.width}
                  fill={textColor}
            />
            <Text
                text={offGrid ? "Off-grid" : Format.forUnit(Math.abs(this.props.power), Unit.Watt)}
                fontFamily="Courier New"
                x={this.props.x}
                y={this.props.y + 20}
                fontSize={constants.textSize}
                align="center" width={this.props.width}
                fill={offGrid ? color : textColor}
            />
        </>
    }

}
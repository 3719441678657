import React from 'react';
import {Line, Rect, Text} from "react-konva";
import Format from "../util/Format";
import {Unit} from "../util/Unit";
import {constants} from "../Constants";

export interface HomeProps {
    x: number;
    y: number;
    width: number;
    height: number;
    power: number;
}

interface HomeState {
}

export class Home extends React.Component<HomeProps, HomeState> {
    state: HomeState = {}

    render() {
        return <>
            <Line
                x={this.props.x} y={this.props.y}
                points={[
                    0, 0,
                    0, -this.props.width + (this.props.height - this.props.width),
                    this.props.width / 2, -this.props.height,
                    this.props.width, -this.props.width + (this.props.height - this.props.width),
                    this.props.width, 0,
                    0, 0
                ]}
                closed
                fill={constants.offlineColor}
            />
            <Line
                x={this.props.x} y={this.props.y}
                points={[
                    5, -this.props.width + (this.props.height - this.props.width) - 5 - 5,
                    this.props.width / 2 - 5, -this.props.height
                ]}
                stroke={constants.offlineColor}
            />
            <Line
                x={this.props.x} y={this.props.y}
                points={[
                    this.props.width - 5, -this.props.width + (this.props.height - this.props.width) - 5 - 5,
                    this.props.width / 2 + 5, -this.props.height
                ]}
                stroke={constants.offlineColor}
            />
            <Rect
                x={(this.props.width / 2) - 10 + this.props.x}
                y={this.props.y + constants.margin - this.props.height + 20}
                width={20}
                height={25}
                fill={constants.backgroundColor}
            />
            <Line
                x={(this.props.width / 2) - 10 + this.props.x}
                y={this.props.y + constants.margin - this.props.height + 20 + (25 / 2)}
                points={[0, 0, 20, 0]}
                stroke={constants.offlineColor}
            />
            <Line
                x={(this.props.width / 2) + this.props.x}
                y={this.props.y + constants.margin - this.props.height + 20}
                points={[0, 0, 0, 25]}
                stroke={constants.offlineColor}
            />

            <Text text="Home" x={this.props.x} y={this.props.y + constants.margin - this.props.height + 64}
                  fontSize={constants.titleSize}
                  fontStyle="bold" align="center" width={this.props.width}/>
            <Text text={Format.forUnit(this.props.power, Unit.Watt)}
                  x={this.props.x} y={this.props.y + constants.margin - this.props.height + 96} fontSize={constants.textSize}
                  align="center" width={this.props.width}/>
        </>        
    }

}
import React from 'react';
import {Circle, Line, Text} from "react-konva";
import Format from "../util/Format";
import {Unit} from "../util/Unit";
import {constants} from "../Constants";

export interface CarProps {
    x: number;
    y: number;
    width: number;
    height: number;
    chargingStatus: string;
    chargerConnected: boolean;
    batteryPercentage: number;
    batteryCapacity: number;
    range: number;
}

interface CarState {
}

export class Car extends React.Component<CarProps, CarState> {
    state: CarState = {}

    render() {
        const showKmOrCapacity = Math.round(new Date().getSeconds() / 5) % 2;
        const color = this.props.batteryPercentage === 100 ? constants.fullyChargedColor
            : this.props.chargingStatus === "CHARGING" ? constants.chargingColor
                : this.props.chargerConnected ? constants.supplyColor
                    : constants.offlineColor;
        const textColor = this.props.batteryPercentage === 100 ? constants.fullyChargedTextColor
            : constants.backgroundColor;
        return <>
            <Line
                x={this.props.x - this.props.width}
                y={this.props.y + (this.props.height / 2)}
                points={[
                    0, 0,
                    0, -40,
                    75, -45,
                    100, -75,
                    150, -65,
                    165, -45,
                    165, 0,
                    0, 0
                ]}
                closed
                fill={color}
            />
            <Line
                x={this.props.x - this.props.width}
                y={this.props.y + (this.props.height / 2)}
                points={[
                    81, -45,
                    101, -70,
                    110, -68,
                    110, -45,
                    80, -45
                ]}
                closed
                fill={constants.backgroundColor}
            />
            <Circle x={this.props.x - this.props.width + 25} y={this.props.y + (this.props.height / 2)} radius={15}
                    fill={constants.backgroundColor}/>
            <Circle x={this.props.x - 25} y={this.props.y + (this.props.height / 2)} radius={15}
                    fill={constants.backgroundColor}/>

            <Circle x={this.props.x - this.props.width + 25} y={this.props.y + (this.props.height / 2)} radius={10}
                    fill={color}/>
            <Circle x={this.props.x - 25} y={this.props.y + (this.props.height / 2)} radius={10}
                    fill={color}/>

            <Circle x={this.props.x - this.props.width + 25} y={this.props.y + (this.props.height / 2)} radius={3}
                    fill={constants.backgroundColor}/>
            <Circle x={this.props.x - 25} y={this.props.y + (this.props.height / 2)} radius={3}
                    fill={constants.backgroundColor}/>

            <Text text="Car" x={this.props.x - this.props.width + (constants.margin * 1.5)}
                  y={this.props.y + (this.props.height / 2) - 35}
                  fontSize={constants.titleSize}
                  fill={textColor}
                  fontStyle="bold"/>
            <Text text={Format.forUnit(this.props.batteryPercentage, Unit.Percentage)}
                  x={this.props.x - this.props.width - 10} y={this.props.y + (this.props.height / 2) - 50}
                  fontSize={constants.textSize}
                  fill={textColor}
                  align="right"
                  width={this.props.width}/>
            <Text
                text={showKmOrCapacity ? Format.forUnit(this.props.batteryCapacity, Unit.WattHour) : this.props.range + " km"}
                x={this.props.x - this.props.width - 10} y={this.props.y + (this.props.height / 2) - 30}
                fontSize={constants.textSize}
                fill={textColor}
                align="right"
                width={this.props.width}/>
        </>
    }

}
import React from 'react';
import {Unit} from "../util/Unit";
import {ProgressBar} from "primereact/progressbar";
import Format from "../util/Format";

interface ItemProps {
    icon: string;
    textColor?: string;
    value?: any;
    value2?: any;
    unit?: Unit;
}

export default function Item(props: ItemProps) {

    function progressBar(textColor?: string) {
        const pattern = /(.*)-(.*?)-(.*)/
        const match = textColor?.match(pattern);
        const color = match != null ? match[2] : "";

        function valueTemplate (value: any) {
            const textColor = color.indexOf("yellow") >= 0 ? "" : "text-white-alpha-90"
            return (
                <React.Fragment>
                    <span className={textColor}>{value}%</span>
                </React.Fragment>
            );
        }

        return (
            <>
                <div className="col-3 white-space-nowrap text-right">
                    <span className="ml-2" dangerouslySetInnerHTML={{__html: props.value2}}></span>
                </div>
                <div className="col-7">
                    <ProgressBar value={props.value} color={color} className="ml-3" displayValueTemplate={valueTemplate}/>
                </div>
            </>
        );
    }

    function generic() {
        return (
            <div className="col-10 text-right">
                {props.unit != null
                    ? Format.forUnit(props.value, props.unit)
                    : props.value
                }
            </div>
        );
    }

    return (
        <div className={props?.textColor + " grid"}>
            <div className="col-2">
                <i className={"bi bi-" + props.icon}/>
            </div>
            {props.unit === Unit.Percentage
                ? progressBar(props?.textColor)
                : generic()
            }
        </div>
    );
}
import React from 'react';
import {Line, Text} from "react-konva";
import Konva from "konva";
import Format from "../util/Format";
import {Unit} from "../util/Unit";
import {constants} from "../Constants";

export interface PowerLineProps {
    x: number;
    y: number;
    points: number[];
    charging: boolean;
    power: number;
    maxHeight: number;
    maxWidth: number;
}

interface PowerLineState {
}

export class PowerLine extends React.Component<PowerLineProps, PowerLineState> {
    state: PowerLineState = {}
    lineRef = React.createRef<Konva.Line>();
    path = new Konva.Path();
    pathLength = 0;
    circle1 = new Konva.Circle();
    circle2 = new Konva.Circle();
    circle3 = new Konva.Circle();
    animation: Konva.Animation | undefined;

    componentDidMount() {
        this.initAnimation(this.lineRef.current);
        this.animate();
    }

    componentDidUpdate(prevProps: Readonly<PowerLineProps>, prevState: Readonly<PowerLineState>, snapshot?: any) {
        if (prevProps.power !== this.props.power) {
            this.initCircles(false);
        }
    }

    componentWillUnmount() {
        if (this.animation !== undefined) {
            this.circle1.hide()
            this.circle2.hide()
            this.circle3.hide()
            this.animation.stop();
        }
    }

    private initAnimation(line: Konva.Line | null) {
        if (line !== null) {
            // create a path based on the line
            this.path.x(line.x());
            this.path.y(line.y());
            let p = "";
            for (let i = 0; i < line.points().length; i += 2) {
                p += (i === 0 ? "M" : " L") + (line.x() + line.points()[i]) + " " + (line.y() + line.points()[i + 1]);
            }
            this.path.data(p);
            this.pathLength = Math.round(this.path.getLength());

            this.initCircles(true);
        }
    }

    private initCircles(firstInit: boolean) {
        let circleRadius = [3 + (this.props.power / 800), 4.5 + (this.props.power / 800), 6 + (this.props.power / 800)];
        // create the circles which will be animated over the path
        this.initCircle(this.circle1, circleRadius[0], firstInit)
        this.initCircle(this.circle2, circleRadius[1], firstInit)
        this.initCircle(this.circle3, circleRadius[2], firstInit)
    }

    private initCircle(circle: Konva.Circle, radius: number, firstInit: boolean) {
        circle.radius(radius);
        circle.stroke(this.props.charging ? constants.chargingColor : constants.supplyColor);
        circle.strokeWidth((this.props.power / 1000) + 1)
        circle.fill(constants.backgroundColor);
        if (firstInit) {
            circle.hide()
            this.lineRef.current?.getLayer()?.add(circle)
        }
    }

    private animate() {
        const circle1 = this.circle1;
        const circle2 = this.circle2;
        const circle3 = this.circle3;
        const self = this;
        const path = this.path;
        const pathLength = this.pathLength;

        this.animation = new Konva.Animation(function (frame) {
            const tick = frame?.time !== undefined ? Math.round(frame.time) : 0;
            self.animateCircle(circle1, 0, tick, pathLength, path);
            self.animateCircle(circle2, 1, tick, pathLength, path);
            self.animateCircle(circle3, 2, tick, pathLength, path);
        })
        this.animation.start();
    }

    private animateCircle(circle: Konva.Circle, circlePositionIndex: number, tick: number, pathLength: number, path: Konva.Path) {
        const circlePositions = [35 + (this.props.power / 200), 20 + (this.props.power / 400), 0]
        const indexOnPath = ((Math.round(tick / 20) - circlePositions[circlePositionIndex]) % pathLength);
        if (indexOnPath >= 0 && indexOnPath < pathLength) {
            circle.position(path.getPointAtLength(indexOnPath));
            circle.show()
        } else {
            circle.hide()
        }
    }

    render() {

        const topQuadrant = this.props.y < this.props.maxHeight / 2;
        const leftQuadrant = this.props.x < this.props.maxWidth / 2;
        const leftDirection = this.props.points[0] > this.props.points[2]
        const upDirection = this.props.points[1] < this.props.points[3]
        const horizontalDirection = this.props.points[1] === this.props.points[3];
        const powerAdjust = (this.props.power / 400);

        const rotation = horizontalDirection ? 0 : -90;

        return <>
            <Line
                x={this.props.x}
                y={this.props.y}
                points={this.props.points}
                stroke={this.props.charging ? constants.chargingColor : constants.supplyColor}
                strokeWidth={(this.props.power / 300) + 1}
                ref={this.lineRef}
            />
            <Text text={Format.forUnit(this.props.power, Unit.Watt)}
                  x={this.props.x + (horizontalDirection ? (leftDirection ? -110 - powerAdjust : 10 + powerAdjust) : (leftQuadrant ? 10 + powerAdjust : -20 - powerAdjust))}
                  y={this.props.y + (horizontalDirection ? (topQuadrant ? 5 + powerAdjust : -17 - powerAdjust) : (upDirection ? 120 + powerAdjust : 40 + powerAdjust))}
                  fontSize={15}
                  align={horizontalDirection ? (leftDirection ? "right" : "left") : "right"} width={100}
                  fill={this.props.charging ? constants.chargingColor : constants.supplyColor}
                  rotation={rotation}
            />
        </>
    }

}
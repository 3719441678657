import React, {useState} from "react";
import axios from "axios";
import Chart from "react-apexcharts";
import {config} from "../Constants";
import {useInterval, useMountEffect} from "primereact/hooks";

export default function ChartInfo() {

    const [chartState, setChartState] = useState<any>();

    const loadDataUsage = () => {
        // get usage
        axios.get<any>(config.url.API_URL + "/api/data/usage")
            .then(response => {
                ApexCharts.getChartByID("usageChart")?.updateSeries(response.data);
            });

        // get annotations
        axios.get<any>(config.url.API_URL + "/api/annotations")
            .then(response => {
                for (let annotation of response.data) {
                    ApexCharts.getChartByID("usageChart")?.addXaxisAnnotation(annotation);
                }
            });
    };

    useMountEffect(() => {
        const options: any = {
            options: {
                chart: {
                    id: 'usageChart',
                    foreColor: '#ccc',
                    type: "line",
                    zoom: {
                        enabled: true,
                    },
                },
                noData: {
                    text: "Loading..."
                },
                colors: ["#e389cf", "#FCCF31", "#17ead9", "#f36e3d", "#3D8DF3", "#95cef8", "#f02fc2"],
                annotations: {
                    xaxis: [],
                    yaxis: [
                        {
                            y: 0,
                            strokeDashArray: 0,
                            borderColor: "#67E09C",
                            borderWidth: 2,
                            opacity: 1
                        }
                    ]
                },
                stroke: {
                    curve: ["straight", "straight", "straight", "straight", "stepline", "straight", "stepline"],
                    width: 2
                },
                tooltip: {
                    theme: 'dark'
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [4, 6],
                    formatter: function (value: any, context: any) {
                        // only show dataLabels when wanted & when values change
                        if (context.w.config.dataLabels.enabledOnSeries.includes(context.seriesIndex)) {
                            const data = context.w.config.series[4].data;

                            if (context.dataPointIndex === 0 || context.dataPointIndex === data.length - 1)
                                return value + "%"
                            else if (data[context.dataPointIndex].y !== data[context.dataPointIndex - 1].y)
                                return value + "%"
                            else
                                return "";
                        }
                        return value;
                    }
                },
                xaxis: {
                    type: "datetime",
                    labels: {
                        formatter: (value: number) => {
                            return new Date(value * 1000).toLocaleTimeString();
                        }
                    }
                },
                yaxis: [
                    {
                        seriesName: "Power grid consumption/supply",
                        labels: {
                            formatter: (value: number) => {
                                return value / 1000 + " kW"
                            }
                        }
                    },
                    {
                        seriesName: "Power grid consumption/supply",
                        labels: {
                            formatter: (value: number) => {
                                return value / 1000 + " kW"
                            }
                        }, show: false
                    },
                    {
                        seriesName: "Power grid consumption/supply",
                        labels: {
                            formatter: (value: number) => {
                                return value / 1000 + " kW"
                            }
                        }, show: false
                    },
                    {
                        seriesName: "Power grid consumption/supply",
                        labels: {
                            formatter: (value: number) => {
                                return value / 1000 + " kW"
                            }
                        }, show: false
                    },
                    {
                        seriesName: "Car battery percentage",
                        opposite: true,
                        labels: {
                            formatter: (value: number) => {
                                return value + "%"
                            }
                        }
                    },
                    {
                        seriesName: "Power grid consumption/supply",
                        labels: {
                            formatter: (value: number) => {
                                return value / 1000 + " kW"
                            }
                        }, show: false
                    },
                    {
                        seriesName: "Car battery percentage",
                        opposite: true,
                        labels: {
                            formatter: (value: number) => {
                                return value + "%"
                            }
                        }, show: false
                    }
                ]

            },
            series: []
        };
        setChartState(options);

        loadDataUsage();
    });

    useInterval(() => loadDataUsage(), 5 * 60 * 1000); // let's keep it refreshed every 5 minutes

    function chart() {
        return (
            <Chart options={chartState.options} series={chartState.series} type="line"/>
        );
    }

    return (
        <>
            {chartState !== undefined ? chart() : ""}
        </>
    );
}

import CarInfo from "./CarInfo";
import PowerInfo from "./PowerInfo";
import WeatherInfo from "./WeatherInfo";
import TotalPowerInfo from "./TotalPowerInfo";
import React from "react";
import OverviewInfoDTO from "../dto/OverviewInfoDTO";

interface CarOverviewProps {
    info: OverviewInfoDTO;
}

export default function CarOverview(props: CarOverviewProps) {

    return (
        <>
            <h2>CURRENT</h2>
            <div className="pl-3">
                <CarInfo info={props.info}/>
                <PowerInfo info={props.info}/>
                <WeatherInfo info={props.info}/>
            </div>
            <h2>TOTAL</h2>
            <div className="pl-3">
                <TotalPowerInfo info={props.info}/>
            </div>
        </>
    );

}
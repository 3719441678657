import React, {useState} from 'react';
import axios from "axios";
import {config} from "../Constants";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import moment from "moment/moment";
import {useMountEffect} from "primereact/hooks";
import {Divider} from "primereact/divider";

export default function EventOverview() {

    const [events, setEvents] = useState<any>({});
    const [selectedDecision, setSelectedDecision] = useState<string>("");

    const loadDataUsage = () => {
        // get annotations
        axios.get<any>(config.url.API_URL + "/api/decisions")
            .then(response => {
                setEvents(response.data);
            });
    };

    useMountEffect(() => loadDataUsage());

    const timestampFormatter = (rowData: any) => {
        return <>{moment.utc(rowData.timestamp).format("DD-MM-YYYY HH:mm:ss")}</>;
    }

    function showDetails() {
        if (selectedDecision !== null && selectedDecision !== "") {
            return <>
                <Divider align="left" className="mt-5">
                    <div className="inline-flex align-items-center">
                        <i className="pi pi-eye mr-2"></i>
                        <b>Status</b>
                    </div>
                </Divider>
                <div dangerouslySetInnerHTML={{
                    __html: selectedDecision
                        .replaceAll("\n", "<br/>")
                        .replaceAll(" ", "&nbsp;&nbsp;&nbsp;")
                }}/>

            </>
        }
        return <></>
    }

    return (
        <>
            <div className="grid">
                <div className="col-12 lg:col-6">
                    <DataTable value={events}
                               stripedRows
                               sortField="timestamp" sortOrder={-1}
                               selectionMode="single" selection={selectedDecision} onSelectionChange={(e) => {
                        setSelectedDecision(JSON.stringify(e.value, null, 2))
                    }}
                    >
                        <Column field="timestamp" sortable header="Timestamp" body={timestampFormatter}/>
                        <Column field="actions" sortable header="Event"/>
                        <Column field="rule" sortable header="Reason"/>
                    </DataTable>
                </div>
                <div className="col-12 lg:col-6">
                    {showDetails()}
                </div>
            </div>
        </>
    );
}
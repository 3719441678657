import React from 'react';
import {Rect, Text} from "react-konva";
import Format from "../util/Format";
import {Unit} from "../util/Unit";
import {constants} from "../Constants";
import Konva from "konva";

export interface HomeBatteryProps {
    x: number;
    y: number;
    width: number;
    height: number;
    percentage: number
    capacity: number
    power: number;
}

interface HomeBatteryState {
}

export class HomeBattery extends React.Component<HomeBatteryProps, HomeBatteryState> {
    state: HomeBatteryState = {};
    color: string | undefined;
    cellRef = React.createRef<Konva.Rect>();
    titleRef = React.createRef<Konva.Text>();
    percentageRef = React.createRef<Konva.Text>();
    capacityRef = React.createRef<Konva.Text>();
    animation: Konva.Animation | undefined;

    componentDidMount() {
        this.animate();
    }

    componentDidUpdate(prevProps: Readonly<HomeBatteryProps>, prevState: Readonly<HomeBatteryState>, snapshot?: any) {
        if (prevProps.power !== this.props.power && this.animation !== undefined) {
            this.animation.stop();
            this.animate()
        }
    }

    componentWillUnmount() {
        if (this.animation !== undefined) {
            this.animation.stop();
        }
    }

    private animate() {
        const self = this;
        const currentCellRef = self.cellRef.current;
        const cellColor = self.color === undefined ? "white" : self.color

        this.animation = new Konva.Animation(function (frame) {
            const tick = frame?.time !== undefined ? Math.round(frame.time / 500) : 0;

            const backgroundColor = tick % 2 === 0 ? constants.backgroundColor : cellColor;
            const textColor = tick % 2 === 0 ? cellColor : constants.backgroundColor;

            currentCellRef?.fill(backgroundColor)
            if (Math.floor(self.props.percentage / 25) === 0) {
                self.capacityRef.current?.fill(textColor);
            } else if (Math.floor(self.props.percentage / 25) === 1) {
                self.percentageRef.current?.fill(textColor);
            } else if (Math.floor(self.props.percentage / 25) === 2) {
                self.titleRef.current?.fill(textColor);
            }

        })
        this.animation.start();
    }

    batteryCell(cellPosition: number, full: boolean, color: string) {
        if (cellPosition === 0)
            return <></>
        const cellHeight = (this.props.height - (constants.margin * 5)) / 4;
        return <>
            <Rect
                x={this.props.x + constants.margin}
                y={this.props.y + ((cellHeight + constants.margin) * (4 - cellPosition)) + constants.margin}
                width={this.props.width - (constants.margin * 2)}
                height={cellHeight}
                cornerRadius={5}
                fill={constants.backgroundColor}
            />
            <Rect
                x={this.props.x + constants.margin + 2}
                y={this.props.y + ((cellHeight + constants.margin) * (4 - cellPosition)) + constants.margin + 2}
                width={this.props.width - (constants.margin * 2) - 4}
                height={cellHeight - 4}
                cornerRadius={5}
                fill={full ? color : constants.backgroundColor}
                ref={this.props.percentage !== 0 && Math.floor(this.props.percentage / 25) + 1 === cellPosition ? this.cellRef : null}
            />
        </>
    }

    render() {
        const hasCapacity = this.props.percentage > 0;
        const fullyCharged = this.props.percentage === 100;
        this.color = fullyCharged ? constants.fullyChargedColor
            : this.props.power > 0 ? constants.chargingColor
                : constants.supplyColor;
        const textColor = fullyCharged ? constants.fullyChargedTextColor
            : constants.backgroundColor;
        return <>
            <Rect
                x={this.props.x}
                y={this.props.y}
                width={this.props.width}
                height={this.props.height}
                cornerRadius={10}
                stroke={this.color}
                fill={hasCapacity ? this.color : constants.backgroundColor}
            />
            <Rect
                x={this.props.x + (this.props.width / 2) - (constants.margin * 4)}
                y={this.props.y - constants.margin - constants.margin}
                width={constants.margin * 8}
                height={constants.margin * 2}
                cornerRadius={10}
                stroke={this.color}
                fill={this.color}
            />
            <Rect
                x={this.props.x + (this.props.width / 2) - (constants.margin * 4)}
                y={this.props.y - constants.margin}
                width={constants.margin * 8}
                height={constants.margin}
                stroke={this.color}
                fill={this.color}
            />
            {fullyCharged ? "" : this.batteryCell(1, this.props.percentage > 25, this.color)}
            {fullyCharged ? "" : this.batteryCell(2, this.props.percentage > 50, this.color)}
            {fullyCharged ? "" : this.batteryCell(3, this.props.percentage > 75, this.color)}
            {fullyCharged ? "" : this.batteryCell(4, this.props.percentage === 100, this.color)}
            <Text text="Battery" x={this.props.x}
                  y={this.props.y + 37}
                  fontSize={constants.titleSize}
                  fontStyle="bold" align="center" width={this.props.width}
                  fill={this.props.percentage > 75 ? textColor : this.color}
                  ref={this.titleRef}
            />
            <Text text={hasCapacity ? Format.forUnit(this.props.percentage, Unit.Percentage) : ""}
                  x={this.props.x}
                  y={this.props.y + 71}
                  align="center" width={this.props.width}
                  fontSize={constants.textSize}
                  fill={this.props.percentage > 50 ? textColor : this.color}
                  ref={this.percentageRef}
            />
            <Text text={hasCapacity ? Format.forUnit(this.props.capacity, Unit.WattHour) : ""}
                  x={this.props.x}
                  y={this.props.y + 101}
                  align="center" width={this.props.width}
                  fontSize={constants.textSize}
                  fill={this.props.percentage > 25 ? textColor : this.color}
                  ref={this.capacityRef}
            />
        </>
    }

}